<template>
  <div>
    <!-- 按钮列表 -->
    <div class="btnList" ref="btnList">
      <el-button class="itemBtn btnColor" @click="addMeunBtn_1"
        >新建一级菜单</el-button
      >
      <el-button class="itemBtn btnColor" @click="toggleRowExpansion(isShow)">{{
        allShow
      }}</el-button>
      <el-button @click="outLogin" class="itemBtn">退出登录</el-button>
    </div>
    <!-- 菜单管理 -->
    <el-table
      :data="Meundata"
      ref="dataTreeList"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="menuName" label="菜单名称" sortable width="180">
      </el-table-column>
      <el-table-column prop="parentName" label="上级菜单" sortable width="180">
      </el-table-column>
      <el-table-column prop="number" label="菜单编号"> </el-table-column>
      <el-table-column prop="sort" label="排序值"> </el-table-column>
      <el-table-column prop="jumpUrl" label="跳转路径"> </el-table-column>
      <el-table-column prop="isCustom" label="是否定制"> </el-table-column>
      <el-table-column prop="number" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="itemClick(scope.row, 2)"
            >编辑</el-button
          >
          <el-button
            v-if="scope.row._level != 3"
            type="text"
            @click="itemClick(scope.row, 3)"
            >添加子菜单</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-dialog
        :title="diaLogTitle"
        :visible.sync="addDialog"
        width="40%"
        @close="handleClose"
      >
        <div>
          <el-form label-position="right" label-width="80px" :model="formData">
            <el-form-item label="菜单编号">
              <el-input v-model="formData.number"></el-input>
            </el-form-item>
            <el-form-item label="菜单名称">
              <el-input v-model="formData.menuName"></el-input>
            </el-form-item>
            <el-form-item label="上级菜单">
              <el-select
                :disabled="isable"
                v-model="formData.parentId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in parentMenuList"
                  :key="item.id + ''"
                  :label="item.menuName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否定制">
              <el-select v-model="formData.isCustom" placeholder="请选择">
                <el-option
                  v-for="item in isCustomList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="排序">
              <el-input v-model="formData.sort"></el-input>
            </el-form-item>
            <el-form-item label="跳转路径">
              <el-input v-model="formData.jumpUrl"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancleBtn">取 消</el-button>
          <el-button type="primary" @click="addMeunSave">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import treeTable from "../components/treeTable/treeTable";
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import mathNum from "@/assets/js/MathNumBer";
export default {
  inject: ["reload"],
  name: "LoginForm",
  data() {
    return {
      addDialog: false,
      diaLogTitle: "新增菜单",
      formData: {
        menuName: "",
        parentId: "",
        number: "",
        sort: 1,
        jumpUrl: "",
        isCustom: "0",
      },
      parentMenuList: [],
      isCustomList: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      columns: [
        {
          text: "菜单名称",
          value: "menuName",
        },
        {
          text: "上级菜单",
          value: "parentName",
        },
        {
          text: "菜单编号",
          value: "number",
        },

        {
          text: "排序值",
          value: "sort",
        },
        {
          text: "跳转路径",
          value: "jumpUrl",
        },
        {
          text: "是否定制",
          value: "isCustom",
        },
      ],
      Meundata: [],
      levArr1: [],
      isable: false, //选上级是否禁用
      allShow: "全关闭",
      isShow: false,
    };
  },
  // components: { treeTable },
  created() {
    this.getSubMenus();
  },
  mounted() {},
  methods: {
    outLogin() {
      window.localStorage.clear();
      this.$router.push({
        path: "/login",
      });
    },
    //切换树形表格展开
    toggleRowExpansion(temp) {
      if (temp) {
        this.allShow = "全关闭";
      } else {
        this.allShow = "全展开";
      }
      this.isShow = !temp;
      this.toggleRowExpansion_forAll(this.Meundata, temp);
    },
    toggleRowExpansion_forAll(data, temp) {
      data.forEach((item) => {
        this.$refs.dataTreeList.toggleRowExpansion(item, temp);
        if (item.children != undefined && item.children != null) {
          this.toggleRowExpansion_forAll(item.children, temp);
        }
      });
    },
    // 表格后面的点击事件
    itemClick(row, type) {
      let param = row;
      //console.log(param);

      if (type == 2) {
        //编辑
        var leves = [
          {
            menuName: param.parentName,
            id: param.parentId,
          },
        ];
        this.formData.parentId = param.parentId;
        this.parentMenuList = leves;
        this.isable = false; //禁用选择上级
        this.updateMenuBtn(param);
      } else if (type == 3) {
        //新增
        var leveAdd = [
          {
            menuName: param.menuName,
            id: param.id,
          },
        ];
        this.formData.parentId = param.id;
        this.parentMenuList = leveAdd;
        this.isable = true; //禁用选择上级
        this.addMeunBtn();
      }
    },
    updateMenu() {
      Api.updateMenu().then();
    },
    // 获取菜单列表
    getSubMenus() {
      Api.getSubMenus().then((res) => {
        let datas = res.data.result || [];

        let arr = [
          {
            menuName: "无上级",
            id: "-1",
          },
        ];
        for (var i = 0; i < datas.length; i++) {
          if (datas[i].parentId == -1) {
            arr.push(datas[i]);
          }
        }
        this.levArr1 = arr; //一级菜单
        this.diGuiGetMeun(datas, "-1", "无上级");
      });
    },
    //递归获取数据
    diGuiGetMeun(data, parentId, parentName) {
      let tree = [];
      let temp;
      let leve = 1;
      data.forEach((item) => {
        if (item.parentId == parentId) {
          let obj = item;
          obj.parentName = parentName;
          obj.leve = leve;
          temp = this.diGuiGetMeun(data, item.id, item.menuName);
          if (temp.length > 0) {
            obj.children = temp;
          }
          tree.push(obj);
        }
      });
      //排序
      for (var i = 0; i < tree.length - 1; i++) {
        if (tree[i].sort > tree[i + 1].sort) {
          let temp = tree[i + 1];
          tree[i + 1] = tree[i];
          tree[i] = temp;
        }
      }
      this.Meundata = tree;
      return tree;
    },

    // 编辑菜单
    updateMenuBtn(row) {
      this.addDialog = true;
      // this.formData =row;
      this.formData.menuName = row.menuName;
      this.formData.parentId = row.parentId + "";
      this.formData.number = row.number;
      this.formData.sort = row.sort;
      this.formData.jumpUrl = row.jumpUrl;
      this.formData.isCustom = row.isCustom;
      this.formData.menuId = row.id;
      // this.formData = row;
      this.diaLogTitle = "编辑菜单";
    },
    // 新增菜单
    addMeunBtn() {
      this.addDialog = true;
      this.diaLogTitle = "新增菜单";
    },
    // 新增一级菜单
    addMeunBtn_1() {
      this.addDialog = true;
      this.isable = false;
      this.diaLogTitle = "新增一级菜单";
      this.parentMenuList = this.levArr1 || [];
    },
    // 取消按钮
    cancleBtn() {
      this.addDialog = false;
      //刷新
      this.reload();
    },
    // 确认新增
    addMeunSave() {
      // let param = {
      //   isCustom: "", //是否定制0=否1=是(默认0),
      //   menuName: "", //菜单名称
      //   number: "", //菜单编号
      //   parentId: "", //上级菜单id(默认-1)
      //   sort: "", //排序值(默认1)
      //   jumpUrl: "", //菜单跳转url
      //   tenantId: "", //租户id(isCustom=1时必传)
      // };
      let param = this.formData;
      if (param.isCustom == 1) {
        param.tenantId = ""; //租户id,暂时没有
      }

      if (this.diaLogTitle == "新增菜单") {
        Api.addMeun(param).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "添加成功");
            setTimeout(() => {
              this.addDialog = false;
              this.getSubMenus();
            }, 1000);
          }
        });
      } else {
        //编辑
        if (param.id) {
          param.menuId = param.id;
        }

        Api.updateMenu(param).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "修改成功");
            setTimeout(() => {
              this.addDialog = false;
              this.getSubMenus();
            }, 1000);
          }
        });
      }
    },
    handleClose() {
      this.formData = {
        menuName: "",
        parentId: "",
        number: "",
        sort: 1,
        jumpUrl: "",
        isCustom: "0",
      };
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .el-select {
  width: 100%;
}
</style>